import React, { useContext, useState } from "react";
import MTypography from "../../../../../Atoms/MTypography";
import classes from "./index.module.css";
import { isLongCharacter, longTextNumber } from "../config";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { copyText } from "../../../../../../utils/helpers/common";
import TagLabel from "../../../../../Atoms/TagLabel";
import {
  ContentStatusEnum,
  ProductDetailsEditableKeys,
  ProductLiveStatusEnum,
  RolesEnum,
} from "../../../../../../utils/constants/enums";
import Highlighter from "react-highlight-words";
import ProductStatus from "../../../../../Molecules/ProductStatus";
import {
  handleEditModeForDetailPageAction,
  saveEditedValueForDetailPageAction,
} from "../../../../../../store/product/productDetails/productDetails.actions";
import { useDispatch, useSelector } from "react-redux";
import { CompleteProductSelector } from "../../../../../../store/product/productDetails/productDetails.selectors";
import MButton from "../../../../../Atoms/MButton";
import EditableInput from "./EditableInput";
import HighlightChanges from "./HighlightChanges";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditNoteOutlinedIcon from "../../../../../../assets/svgs/edit-icon-active.svg";
import { findSubstringIndices, formatBulletsPoints } from "../../../../../../utils/helpers/strings";
import { DbUserSelector } from "../../../../../../store/user/user.selector";
import { toast } from "react-toastify";
import { errorHandler } from "../../../../../../utils/helpers/apis";
import { wordLengthWithOutSpaces } from "../../../../../../utils/helpers/general-helpers";
import {
  checkIsTrailCompany,
  checkIsUser,
  showDensityOnDetailsPage,
  showDensityOnDetailsPageCompany,
} from "../../../../../../utils/helpers/priviligesChecks";
import PricingContext from "../../../../../../context/PricingContext";
import KeywordDensity from "../../../../../Molecules/keywordDensity/KeywordDensity";
import { Box } from "@mui/material";
import { CompanySelector } from "../../../../../../store/company/company.selector";
import ToastError from "../../../../../Atoms/ToastError";
import { UnlockGreaterPossibilities } from "../../../../../../utils/constants/messages/errors";
import { useDeviceType } from "../../../../../../hooks/useDeviceType";
import ProductDetailsTabCardMenuM from "../../../../../Molecules/ProductDetailsTabCardMenuM";

interface TabCardProps {
  body: string;
  heading?: string;
  showKeywordDensity?: boolean;
  betaTag?: boolean;
  liveTag?: boolean;
  liveProperty?: boolean;
  textLength?: number;
  aiGenerated?: boolean;
  showLiveClasses?: boolean;
  isNewBulletLayout?: boolean;
  showEditButton?: boolean;
  productKey: ProductDetailsEditableKeys;
  bulletIndex?: number;
  showWithoutSpaces?: boolean;
  comparableValue: string;
}
const TabCard = ({
  heading,
  showKeywordDensity = false,
  body,
  textLength = longTextNumber,
  aiGenerated,
  betaTag,
  liveProperty,
  liveTag,
  showLiveClasses,
  productKey,
  bulletIndex,
  showWithoutSpaces,
  isNewBulletLayout = false,
  showEditButton = false,
  comparableValue,
}: TabCardProps) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(DbUserSelector);
  const currentCompany = useSelector(CompanySelector);
  const product = useSelector(CompleteProductSelector);
  const productStatus = product?.status;

  const highlighted = product?.highlightedKeyword;
  const highlightedClassName = product?.highlightClass ? product?.highlightClass : "Highlighted";

  const isLong = isLongCharacter(showWithoutSpaces ? comparableValue?.replace(/\s/g, "") : comparableValue, textLength);
  const [showChanges, setShowChanges] = useState<boolean>(false);
  const [bulletPointCharacterCount, setBulletPointCharacterCount] = useState<number>(0);
  const { onOpen } = useContext(PricingContext);
  const [showPricingPlan] = useState<boolean>(() =>
    currentUser?.companyID ? checkIsTrailCompany(currentCompany?.role) : checkIsUser(currentUser?.role)
  );
  const { isDesktop } = useDeviceType();
  const editMode: boolean = Boolean(product[`${productKey}EditMode`]);

  const [menuState, setMenuState] = useState<any>({ openPopper: false, anchorEl: null });

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (product && product.status === ContentStatusEnum.APPROVED) {
      setShowChanges(false);
    }
  }, [productStatus]);

  const disableEditMode = () => {
    if (productKey) dispatch(handleEditModeForDetailPageAction(product, productKey, false));
  };
  const enableEditMode = () => {
    if (productKey) dispatch(handleEditModeForDetailPageAction(product, productKey, true));
  };

  const updateData = () => {
    if (productKey)
      dispatch(saveEditedValueForDetailPageAction(product, productKey)).catch((e: any) => {
        toast.error(errorHandler(e));
      });
  };

  const getContent = () => {
    if (!aiGenerated) return body;
    if (showWithoutSpaces && heading === "Generic Keywords (Search Terms)")
      return comparableValue ? comparableValue.toLowerCase() : "";
    else if (showWithoutSpaces) return body?.replace(/\s/g, "");
    else if (bulletIndex || bulletIndex === 0) {
      if (product?.status === ContentStatusEnum.APPROVED) {
        return product?.approvedProduct[productKey][bulletIndex] || "";
      } else if (product?.status === ContentStatusEnum.DRAFT || product?.status === ContentStatusEnum.PUBLISHED) {
        return product?.aiGeneratedProduct[productKey][bulletIndex] || "";
      } else if (product?.status === ContentStatusEnum.EDITED) {
        return product?.editedProduct[productKey][bulletIndex] || "";
      } else if (product?.status === ContentStatusEnum.ERROR) {
        return product?.editedProduct[productKey][bulletIndex] || "";
      }
    } else {
      if (product?.status === ContentStatusEnum.APPROVED) {
        return product?.approvedProduct[productKey] || "";
      } else if (product?.status === ContentStatusEnum.DRAFT || product?.status === ContentStatusEnum.PUBLISHED) {
        return product?.aiGeneratedProduct[productKey] || "";
      } else if (product?.status === ContentStatusEnum.EDITED) {
        return product?.editedProduct[productKey] || "";
      }
    }
    return comparableValue || "";
  };

  const getComparableValues = () => {
    const isBulletFormat = Boolean(bulletIndex || bulletIndex === 0);

    if (product?.status === ContentStatusEnum.APPROVED)
      return {
        old: isBulletFormat
          ? (product?.aiGeneratedProduct[productKey][bulletIndex as number] as string)
          : (product?.aiGeneratedProduct[productKey] as string),
        new: isBulletFormat
          ? (product?.approvedProduct[productKey][bulletIndex as number] as string)
          : (product?.approvedProduct[productKey] as string),
      };
    else
      return {
        old: isBulletFormat
          ? (product?.aiGeneratedProduct[productKey][bulletIndex as number] as string)
          : (product?.aiGeneratedProduct[productKey] as string),
        new: isBulletFormat
          ? (product?.editedProduct[productKey][bulletIndex as number] as string)
          : (product?.editedProduct[productKey] as string),
      };
  };

  const blurString = (inputString: string | string[]) => {
    if (Array.isArray(inputString)) {
      console.error("Input must be a string, not an array");
      return null;
    }

    if (inputString?.length <= 44) {
      return <span>{inputString}</span>;
    }

    let firstPart = inputString?.substring(0, 44);
    let remainingPart = inputString?.substring(44);
    // let blurredPart = 'O'.repeat(remainingPart?.length || 0);
    let blurredPart = remainingPart
      ?.split("")
      .map((char) => (char === " " ? " " : "0"))
      .join("");
    const makeContentBlur = currentUser?.companyID
      ? currentCompany?.role === RolesEnum.TRIAL
      : currentUser?.role === RolesEnum.USER;
    let secondPart = makeContentBlur && aiGenerated ? blurredPart : remainingPart;

    return (
      <>
        <span>{firstPart}</span>
        <span className={makeContentBlur && aiGenerated ? classes.Blurred : ""}>{secondPart}</span>
      </>
    );
  };

  const showUpgradeMessage = () => {
    if (currentUser?.companyID) {
      if (currentUser?.role === RolesEnum.COMPANY_ADMIN) {
        onOpen();
      } else {
        toast.warn(<ToastError error={UnlockGreaterPossibilities} showPricingPlan={false} />);
      }
    } else {
      onOpen();
    }
  };

  const handleShowEditsClick = () => {
    showPricingPlan ? showUpgradeMessage() : setShowChanges(!showChanges);
  };

  const handleEditClick = () => {
    showPricingPlan ? showUpgradeMessage() : enableEditMode();
  };

  const handleCopyClick = () => {
    showPricingPlan ? showUpgradeMessage() : copyText(getContent() as string);
  };

  return (
    <>
      {isNewBulletLayout ? (
        <div />
      ) : (
        <>
          <div className={`d-flex justify-content-between w-100 align-items-center ${classes.HeaderButtonContainer}`}>
            <div className={"d-flex align-items-center"}>
              {liveTag && (
                <div className={"px-2 d-flex align-items-center"}>
                  <ProductStatus
                    type={liveProperty ? ProductLiveStatusEnum.LIVE : ProductLiveStatusEnum.INACTIVE}
                    showInfo={false}
                    variant={"small"}
                  />
                </div>
              )}
              {heading && (
                <Box component={"div"} className="d-flex align-items-center" gap={{ xs: "8px", md: "16px" }}>
                  <MTypography variant={"subtitle1"} customClass={classes.Heading}>
                    {heading}
                  </MTypography>
                  {(currentUser?.companyID
                    ? showDensityOnDetailsPageCompany(currentUser?.role, currentCompany?.role)
                    : showDensityOnDetailsPage(currentUser?.role)) &&
                    showKeywordDensity &&
                    product?.status !== ContentStatusEnum.ERROR &&
                    !editMode && <KeywordDensity title={heading} />}
                </Box>
              )}
              {betaTag && <TagLabel type={"Beta"} label={"Beta"} />}
            </div>

            <div className={"d-flex align-items-center gap-3"}>
              <div>
                {!editMode ? (
                  <>
                    <div className={"d-flex align-items-center gap-3"}>
                      {isDesktop && (
                        <>
                          {product?.status !== ContentStatusEnum.DRAFT &&
                            product?.status !== ContentStatusEnum.ERROR &&
                            !showWithoutSpaces &&
                            aiGenerated && (
                              <div
                                className={`d-flex align-items-center cursor-pointer HoverEffectForButton`}
                                // onClick={() => (showPricingPlan ? showUpgradeMessage() : setShowChanges(!showChanges))}
                                onClick={handleShowEditsClick}
                              >
                                {showChanges ? (
                                  <CheckBoxIcon className={`${classes.CopyIcon} ${classes.ShowEditsIcon}`} />
                                ) : (
                                  <CheckBoxOutlineBlankOutlinedIcon className={classes.CopyIcon} />
                                )}

                                <MTypography
                                  variant={"subtitle2"}
                                  customClass={`${classes.Limit} ${classes.ShowEditsIcon}`}
                                >
                                  {"Show Edits"}
                                </MTypography>
                              </div>
                            )}

                          {showEditButton &&
                            aiGenerated &&
                            product?.status !== ContentStatusEnum.APPROVED &&
                            product?.status !== ContentStatusEnum.PUBLISHED &&
                            product?.status !== ContentStatusEnum.ERROR &&
                            !showChanges && (
                              <div
                                className={`d-flex align-items-center cursor-pointer HoverEffectForButton`}
                                // onClick={() => (showPricingPlan ? showUpgradeMessage() : enableEditMode())}
                                onClick={handleEditClick}
                              >
                                <img src={EditNoteOutlinedIcon} alt={"alt-icon"} className={classes.CopyIcon} />
                                <div>
                                  <MTypography
                                    variant={"subtitle2"}
                                    customClass={`${classes.Limit} ${classes.ShowEditsIcon}`}
                                  >
                                    {"Edit"}
                                  </MTypography>
                                </div>
                              </div>
                            )}

                          {body?.length > 0 && aiGenerated && (
                            <div
                              className={`d-flex align-items-center cursor-pointer HoverEffectForButton`}
                              // onClick={() => (showPricingPlan ? showUpgradeMessage() : copyText(getContent() as string))}
                              onClick={handleCopyClick}
                            >
                              <ContentCopyOutlinedIcon className={classes.CopyIcon} />
                              <div>
                                <MTypography
                                  variant={"subtitle2"}
                                  customClass={`${classes.Limit} ${classes.ShowEditsIcon}`}
                                >
                                  {"Copy"}
                                </MTypography>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <div>
                        <MTypography
                          variant={"subtitle2"}
                          customClass={`${classes.Limit} ${isLong && classes.LimitExceed}`}
                        >
                          {showWithoutSpaces && isDesktop ? (
                            <>
                              {wordLengthWithOutSpaces(getContent() as string) || 0}/{textLength}{" "}
                              {showWithoutSpaces ? `bytes (does not include spaces)` : ""}
                            </>
                          ) : (
                            <>{!showWithoutSpaces && `${(getContent() as string)?.length}/${textLength}`}</>
                          )}
                        </MTypography>
                      </div>
                      {!isDesktop && (
                        <div>
                          <ProductDetailsTabCardMenuM
                            setMenuState={setMenuState}
                            menuState={menuState}
                            handleCopyClick={handleCopyClick}
                            handleEditClick={handleEditClick}
                            handleShowEditsClick={handleShowEditsClick}
                            isShowEditOptionVisible={
                              product?.status !== ContentStatusEnum.DRAFT &&
                              product?.status !== ContentStatusEnum.ERROR &&
                              !showWithoutSpaces &&
                              aiGenerated
                            }
                            isEditOptionVisible={
                              showEditButton &&
                              aiGenerated &&
                              product?.status !== ContentStatusEnum.APPROVED &&
                              product?.status !== ContentStatusEnum.ERROR &&
                              !showChanges
                            }
                            isCopyOptionVisible={body?.length > 0 && aiGenerated}
                            showChanges={showChanges}
                            isBulletPointsWrapper={false}
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className={"d-flex align-items-center gap-3"}>
                    <div onClick={disableEditMode}>
                      <MTypography variant={"subtitle2"} customClass={`${classes.Limit} cursor-pointer`}>
                        {"Cancel"}
                      </MTypography>
                    </div>

                    <MButton size={"small"} onClick={updateData}>
                      {"Save"}
                    </MButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showWithoutSpaces && !isDesktop && (
            <MTypography
              variant={"subtitle2"}
              customClass={`${classes.Limit} ${isLong && classes.LimitExceed} ${classes.ShowWithoutSpaces} text-end`}
            >
              {wordLengthWithOutSpaces(getContent() as string) || 0}/{textLength}{" "}
              {showWithoutSpaces ? `bytes (does not include spaces)` : ""}
            </MTypography>
          )}
        </>
      )}

      <div
        className={`${isNewBulletLayout ? classes.IsNewBodyContainer : classes.BodyContainer} ${
          isLong && classes.BodyContainerLimitExceed
        } ${showLiveClasses && !liveProperty && classes.InActive} ${
          editMode && !isNewBulletLayout && classes.EditBorder
        }`}
      >
        {isNewBulletLayout && (
          <div className={`d-flex justify-content-end align-items-center w-100 gap-3 ${classes.CopyLengthContainer}`}>
            <div className={`d-flex align-items-center gap-3 HoverEffectForButton`}>
              {body?.length > 0 && aiGenerated && !editMode && (
                <div
                  className={"d-flex align-items-center cursor-pointer"}
                  onClick={() =>
                    showPricingPlan
                      ? showUpgradeMessage()
                      : copyText(formatBulletsPoints(getContent() as string, currentUser?.bulletPointsFormat))
                  }
                >
                  <ContentCopyOutlinedIcon className={classes.CopyIcon} />
                  <div>
                    <MTypography variant={"subtitle2"} customClass={`${classes.Limit} ${classes.ShowEditsIcon}`}>
                      {"Copy"}
                    </MTypography>
                  </div>
                </div>
              )}
            </div>
            <div>
              <MTypography
                variant={"subtitle2"}
                customClass={`${classes.Limit} ${
                  ((editMode && bulletPointCharacterCount > textLength) ||
                    (!editMode && getContent()?.length > textLength)) &&
                  classes.LimitExceed
                }`}
              >
                {`${
                  editMode ? bulletPointCharacterCount : getContent()?.length || 0
                  // valueToBeEdited?.length ? valueToBeEdited.length : body?.length ? body.length : 0
                }/${textLength}`}
              </MTypography>
            </div>
          </div>
        )}

        {showChanges || (isNewBulletLayout && product?.showBulletPointChanges) ? (
          <>
            <HighlightChanges
              oldStr={getComparableValues().old}
              newStr={getComparableValues().new}
              isInBulletFormat={isNewBulletLayout}
            />
          </>
        ) : (
          <>
            {!editMode ? (
              <MTypography variant={"subtitle2"} customClass={`${classes.Body} ${classes.TransparentBorder}`}>
                {highlighted?.length && aiGenerated ? (
                  <>
                    <Highlighter
                      searchWords={[highlighted]}
                      autoEscape={true}
                      // textToHighlight={getContent() as string}
                      textToHighlight={
                        isNewBulletLayout
                          ? formatBulletsPoints(getContent() as string, currentUser?.bulletPointsFormat)
                          : (getContent() as string)
                      }
                      highlightClassName={classes[highlightedClassName]}
                      // working below
                      findChunks={(options) => {
                        const { searchWords, textToHighlight } = options;
                        const matchString: string = searchWords[0] as string;
                        return findSubstringIndices(textToHighlight, matchString);
                      }}
                    />
                  </>
                ) : (
                  <>
                    {
                      isNewBulletLayout
                        ? blurString(
                            formatBulletsPoints(
                              // product?.status === ContentStatusEnum.APPROVED
                              //   ? body || comparableValue || ""
                              //   : comparableValue || body || "",
                              getContent() as string,
                              currentUser?.bulletPointsFormat
                            )
                          )
                        : showWithoutSpaces
                        ? blurString((comparableValue || body)?.toLowerCase())
                        : blurString(getContent())
                      //       product?.status === ContentStatusEnum.APPROVED
                      // ? body || comparableValue
                      // : comparableValue || body
                    }
                  </>
                )}
              </MTypography>
            ) : (
              <EditableInput
                editableKey={productKey as ProductDetailsEditableKeys}
                bulletIndex={bulletIndex}
                characterCount={setBulletPointCharacterCount}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TabCard;
