import { camelCaseToPascalCaseWithSpaces } from "../../../../utils/helpers/strings";
import ArrowUp from "../../../../assets/svgs/events/arrow-up.svg";
import ArrowDown from "../../../../assets/svgs/events/arrow-down.svg";
import moment from "moment";
export const configureEventsResponse = (events: any): any => {
  const transformEvents: any = [];

  if (events.length > 0) {
    events.forEach((event: any) => {
      const year = parseInt(moment(new Date(event.date)).format("YYYY"));
      const month = parseInt(moment(new Date(event.date)).format("MM"));
      const day = parseInt(moment(new Date(event.date)).format("DD"));
      const date = new Date(year, month - 1, day, 0, 0, 0);
      switch (event.event) {
        case "price":
          transformEvents.push({
            ...event,
            title: (
              <div className={"d-flex gap-2"}>
                <div className={"event-text-div"}>{`${camelCaseToPascalCaseWithSpaces(event.event)} `}</div>
                <img src={event.status === "increased" ? ArrowUp : ArrowDown} width={10} alt="" />
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: "#E6B540",
          });
          break;
        // case "buyBox":
        //   transformEvents.push({
        //     ...event,
        //     title: "Lunch",
        //     start: new Date(2015, 3, 12, 12, 0, 0, 0),
        //     end: new Date(2015, 3, 12, 13, 0, 0, 0),
        //     desc: "Power lunch",
        //   });
        //   break;
        // case "bestSellerBadge":
        //   transformEvents.push({
        //     ...event,
        //     title: "Lunch",
        //     start: new Date(2015, 3, 12, 12, 0, 0, 0),
        //     end: new Date(2015, 3, 12, 13, 0, 0, 0),
        //     desc: "Power lunch",
        //   });
        //   break;
        //
        // case "limitedTimeDealBadge":
        //   transformEvents.push({
        //     ...event,
        //     title: "Lunch",
        //     start: new Date(2015, 3, 12, 12, 0, 0, 0),
        //     end: new Date(2015, 3, 12, 13, 0, 0, 0),
        //     desc: "Power lunch",
        //   });
        //   break;
        //
        // case "amazonsChoice":
        //   transformEvents.push({
        //     ...event,
        //     title: "Lunch",
        //     start: new Date(2015, 3, 12, 12, 0, 0, 0),
        //     end: new Date(2015, 3, 12, 13, 0, 0, 0),
        //     desc: "Power lunch",
        //   });
        //   break;
        //
        // case "couponTextBadge":
        //   transformEvents.push({
        //     ...event,
        //     title: "Lunch",
        //     start: new Date(2015, 3, 12, 12, 0, 0, 0),
        //     end: new Date(2015, 3, 12, 13, 0, 0, 0),
        //     desc: "Power lunch",
        //   });
        //   break;
        case "negativeReviews":
          transformEvents.push({
            ...event,
            title: (
              <div className={"d-flex gap-2"}>
                <div className={"event-text-div"}>{`${camelCaseToPascalCaseWithSpaces(event.event)} ${
                  event.count
                } `}</div>
                <img src={ArrowUp} width={10} alt="" />
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: "#E6B540",
          });
          break;
        case "negativeReviewsOnPage":
          transformEvents.push({
            ...event,
            title: (
              <div className={"d-flex gap-2"}>
                <div className={"event-text-div"}>{`${camelCaseToPascalCaseWithSpaces(event.event)} ${
                  event.count
                } `}</div>
                <img src={ArrowUp} width={10} alt="" />
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: "#E6B540",
          });
          break;
        case "variants":
          transformEvents.push({
            ...event,
            title: (
              <div className={"d-flex gap-2"}>
                <div className={"event-text-div"}>{`${camelCaseToPascalCaseWithSpaces(event.event)} ${
                  event.activeVariantAsin
                } `}</div>
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: "#E6B540",
          });
          break;
        default:
          transformEvents.push({
            ...event,
            title: (
              <div className={"d-flex gap-2"}>
                <div className={"event-text-div"}>{`${camelCaseToPascalCaseWithSpaces(event.event)}`}</div>
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: event.exist ? "#0CC683" : "#FF4C05",
          });
          break;
      }
    });
  }

  return transformEvents;
};
