import { isAxiosError } from "axios";
import { AsinKeywordHistory } from "../../store/product/product.reducers";

export const ignoreStatusCode = [403, 502, 504, "ECONNABORTED"];
export const getErrorStatus = (e: any) => e?.response?.status || e?.code || 0;

export function errorHandler(e: any) {
  if (!ignoreStatusCode.includes(getErrorStatus(e))) {
    if (isAxiosError(e)) {
      return e.response ? e.response.data.message : e.message;
    } else {
      // return e?.message || "An error occurred. Please wait a moment and retry. Contact support if the issue persists.";
      return e?.message || "App is busy or undergoing maintenance. Please wait a moment and retry. Contact support if the issue persists.";
    }
  } else {
    return isBadGateway(e) ? "Processing your request. Thank you for your patience." : "";
  }
}

export const handleAuthError = (error: any) => {
  const errorMessages: any = {
    "auth/too-many-requests":
      "Too many login attempts detected. Please pause for a moment and retry for security purposes.",
    "auth/user-token-expired": "Your session has expired. Please sign in again to maintain security and continue.",
    "auth/invalid-user-token": "Session invalid or expired. Please re-login for secure access.",
    "auth/network-request-failed": "Network error detected. Please check your internet connection and try again.",
    "auth/requires-recent-login":
      "A recent login is required for this action. Please sign in again to proceed securely.",
    "auth/user-cancelled": "Operation canceled by user. Your data remains unchanged.",
    "auth/user-token-revoked": "Your session has been revoked for security reasons. Please sign in again.",
    "auth/weak-password": "For enhanced security, please create a stronger password using a mix of characters.",
    "auth/email-already-in-use":
      "This email address is already registered. Please use a different email for registration.",
    "auth/operation-not-allowed":
      "This operation is currently restricted. For assistance, please contact our support team.",
    "auth/invalid-credential": "Invalid email address or password. Please verify your credentials and try again.",
    "auth/invalid-email": "Invalid email address or password. Please verify your credentials and try again.",
    "auth/user-disabled": "Your account has been disabled. Contact our support team for assistance.",
    "auth/user-not-found": "User not found with the provided email and password. Please verify your credentials and try again.",
    "auth/wrong-password": "Invalid email address or password. Please verify your credentials and try again.",
    "auth/captcha-check-failed": "Verification process failed. Please retry to complete the action.",
    "auth/invalid-email-verified": "Email address not verified. Check your inbox to verify and proceed.",
    "auth/credential-already-in-use": "Credential already in use. Please choose a different one for secure access.",
    "auth/missing-email": "Email address required for authentication. Please provide a valid email.",
  };
  console.error("Error Code", error?.code);
  return errorMessages[error.code] || "An error occurred. Please try again later.";
};

export const transformPrioritizeKeywordsArray = (keywords: any) => {
  let result: any = [];
  if (keywords?.length > 0) {
    keywords.forEach((keyword: any) => {
      if (typeof keyword === "string") {
        result = [
          ...result,
          {
            phrase: keyword,
            searchVolume: 0,
          },
        ];
      } else {
        result = [...result, keyword];
      }
    });
    return result;
  }
  return [];
};

export const transformKeywordsHistory = (keywordsHistory: any[], productASIN: string) => {
  let keywordHistoryResult: AsinKeywordHistory = {};
  if (keywordsHistory.length > 0) {
    keywordsHistory.forEach((item: any) => {
      item?.oldSelectedHelium10Keywords.forEach((keyword: any) => {
        if (keywordHistoryResult.hasOwnProperty(keyword.phrase)) {
          keywordHistoryResult = {
            ...keywordHistoryResult,
            [keyword.phrase]: [
              ...keywordHistoryResult[keyword.phrase],
              {
                date: item.lastCreatedAt,
                phrase: keyword.phrase,
                blacklistedWord: keyword.blacklistedWord,
                relevancy: keyword.relevancy,
                count: keyword.count,
                competitiveness: keyword.competitiveness,
                rank: keyword[productASIN],
              },
            ],
          };
        } else {
          keywordHistoryResult = {
            ...keywordHistoryResult,
            [keyword.phrase]: [
              {
                date: item.lastCreatedAt,
                phrase: keyword.phrase,
                blacklistedWord: keyword.blacklistedWord,
                relevancy: keyword.relevancy,
                count: keyword.count,
                competitiveness: keyword.competitiveness,
                rank: keyword[productASIN],
              },
            ],
          };
        }
      });
    });
  }
  return keywordHistoryResult;
};

export const isBadGateway = (e: any): boolean => {
  if (
    e?.response?.status === 504 ||
    e?.response?.status === "504" ||
    e?.response?.status === 502 ||
    e?.response?.status === "502"
  ) {
    return true;
  }
  const status = getErrorStatus(e);
  console.log("status => ", status);
  return status === 502 || status === 504 || status === "ECONNABORTED";
};
export const ApiDelayTimeInMilliseconds = 20000;
export const ApiRetries = 3;
export function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
