import { fetchAmazonASIN } from "../../../../utils/helpers/regex";
import * as XLSX from "xlsx";

export const bulkAsinsLimitUsingFile = 500;

export const instructionsList = [
  "You can upload only CSV or XLSX file.",
  "File must have ASINS column.",
  "File size should not exceed 10MB.",
  "You can download sample file.",
  `Maximum upload limit is ${bulkAsinsLimitUsingFile} ASINs.`,
];

export const handleDownload = (fileType: string) => {
  const sampleData: string[] = [
    "Product ID(ASIN)",
    "B00065V000",
    "B000YZ1001",
    "B01IU6I002",
    "B0BNJG7003",
    "B0013J6004",
    "B06XV21005",
    "B0086A7006",
    "B000BXO007",
    "B004H37008",
    "B007QU2009",
  ];

  // const type = fileType === "csv" ? "text/csv;charset=utf-8;" : "application/vnd.ms-excel;charset=utf-8;";
  const type = fileType === "csv" ? "text/csv;charset=utf-8;" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;";

  
  const fileName = fileType === "csv" ? "Csv_Sample.csv" : "Xlsx_Sample";
  const fileContent = sampleData.join("\n");
  const blob = new Blob([fileContent], { type: type });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const arrayContainsAllUndefined = (arr: any[]) => {
  return arr.every((element) => element === undefined);
};


// used in parseFile method
const generateArray = (rowData: string[]) => {
  let onlyValidAsins: string[] = [];
  rowData?.forEach((row: string) => {
    const asin: string | false = fetchAmazonASIN(row);
    if (asin && asin.length === 10 && asin.substring(0, 2) === "B0") {
      onlyValidAsins.push(row);
    }
  });
  return onlyValidAsins;
};

export const parseFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = event?.target?.result;

      if (file.name.endsWith(".csv")) {
        if (typeof data === "string") {
          const rows = data.split(/\r?\n/);
          const filteredList = generateArray(rows);
          resolve(filteredList);
        }
      } else if (file.name.endsWith(".xlsx")) {
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheettocsv = XLSX.utils.sheet_to_csv(sheet);
        const rows = sheettocsv.split(/\r?\n/);
        const filteredList = generateArray(rows);
        resolve(filteredList);
      } else {
        reject(new Error("Unsupported file format"));
      }
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsBinaryString(file);
  });
};

export function isArrayOfString(value: any): value is string[] {
  return Array.isArray(value) && value.every((item) => typeof item === "string");
}
