import React, { memo, useContext, useEffect, useState } from "react";
import { GridCellParams, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { ContentStatusEnum, CountryDomainEnum, RolesEnum, TabsEnum } from "../../../../utils/constants/enums";
import { Product, UpdateBulkProductPayload } from "../../../../apis/types/generate-product";
import { CircularProgress } from "@mui/material";

import ProductCardSkeleton from "../ProductCardSkeleton/ProductCardSkeleton";
import { copyAsinByRightClick, getExceptionError } from "../../../../utils/helpers/common";
import { useSelector } from "react-redux";
import {
  showDeleteAndEditIconOnWaitingGrid,
  showDeleteIconOnWaitingGrid,
  showDeleteIconOnWaitingGridCompany,
  showMultipleProductDeleteButton,
  showUserEmailInProductsPage,
} from "../../../../utils/helpers/priviligesChecks";
import { DbUserSelector, UserRoleSelector } from "../../../../store/user/user.selector";
import classes from "./WaitingGrid.module.css";
import ProductTable from "../ProductDataGrid/ProductTable";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { usePagination } from "../../../../hooks/usePagination";
import ProductStatus from "../../../Molecules/ProductStatus";
import MTypography from "../../../Atoms/MTypography";
import { ProductApis } from "../../../../apis/product";
import MButton from "../../../Atoms/MButton";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import ActionBox from "../../../Molecules/ActionBox";

import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { generateProductApis } from "../../../../apis/generate-product";
import { toast } from "react-toastify";
import { SUCC_DELETE_PRODUCT, SUCC_UPDATE_PRODUCT } from "../../../../utils/constants/messages/success";
import { errorHandler } from "../../../../utils/helpers/apis";
import UpdateWaitingProductBox from "./UpdateWaitingProductBox/UpdateWaitingProductBox";
import { CountryDomainObject, getCountryDomainObjectByName } from "../../DomainDropDown/config";
import RefreshIcon from "@mui/icons-material/Refresh";
import { isOwnerReachedContentGenLimit } from "./config";
import ToastError from "../../../Atoms/ToastError";
import { UnlockGreaterPossibilities, UserReachedRegenLimit } from "../../../../utils/constants/messages/errors";
import PricingContext from "../../../../context/PricingContext";
import { CompanySelector } from "../../../../store/company/company.selector";

type ProductDataGridProps = {
  tab?: TabsEnum;
  isRerun?: boolean;
  isChildComponent?: boolean;
  refreshWaitingGrid?: boolean;
  setRefreshWaitingGrid?: React.Dispatch<React.SetStateAction<boolean>>;
  teamUserId?: string;
  isCompany?: boolean;
};

const defaultLimit = 10;
const WaitingGrid = ({
  isChildComponent,
  refreshWaitingGrid,
  setRefreshWaitingGrid,
  teamUserId = "",
  isCompany = false,
}: ProductDataGridProps) => {
  const { isDesktop } = useDeviceType();
  const dbUser = useSelector(DbUserSelector);
  const userRole = useSelector(UserRoleSelector);
  const currentCompany = useSelector(CompanySelector);

  const [loading, setLoading] = useState<boolean>(false);

  const [products, setProducts] = useState<any>([]);
  const [totalProducts, setTotalProducts] = useState<number>(0);
  const [limit, setLimit] = useState<number>(defaultLimit);
  const { pageSize, setPageNumber, pageNumber, setPageSize, setSortModel } = usePagination(50);

  const [expanded, setExpanded] = React.useState(false);
  const [state, setState] = useState<any>({
    loading: false,
    openDialogBox: false,
    openDeleteConfirm: false,
    currentDeleteId: "",
  });
  const [openMultipleDeleteConfirm, setOpenMultipleDeleteConfirm] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [showDeleteIconBar, setShowDeleteIconBar] = useState<boolean>(false);
  const [showUpdateProductBox, setShowUpdateProductBox] = useState<boolean>(false);
  const { onOpen } = useContext(PricingContext);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  const closeConfirmDelete = () => {
    if (state.openDeleteConfirm) setState({ ...state, openDeleteConfirm: false, currentDeleteId: "" });
    else if (openMultipleDeleteConfirm) setOpenMultipleDeleteConfirm(false);
  };

  const fetchProducts = () => {
    setLoading(true);
    ProductApis.getBulkGogoProducts(limit, isCompany && teamUserId ? teamUserId : "")
      .then((res: any) => {
        if (res.data.products.length > 0) {
          const fetchedProducts = res.data.products.map((product: any) => ({
            ...product,
            title: "",
            productBrand: "",
            productType: "",
            delete: "",
            isSelected: false,
            id: product?._id,
          }));
          setProducts(fetchedProducts);
          setLoading(false);
          if (setRefreshWaitingGrid) setRefreshWaitingGrid(false);
        } else {
          setProducts([]);
        }
        setTotalProducts(res.data.totalProducts);
      })
      .catch((e: any) => {
        setLoading(false);
        if (setRefreshWaitingGrid) setRefreshWaitingGrid(false);
        console.log("error while fetching bulk gogo", e);
      });
  };

  const [allSelectedProductsM, setAllSelectedProductsM] = useState<string[]>([]);
  const handleMultiCardSelectionM = (id: string) => {
    const index = allSelectedProductsM.indexOf(id);
    if (index === -1) {
      setAllSelectedProductsM([...allSelectedProductsM, id]);
    } else {
      const updatedSelectedProducts = [...allSelectedProductsM];
      updatedSelectedProducts.splice(index, 1);
      setAllSelectedProductsM(updatedSelectedProducts);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchProducts();
  }, [limit]);
  useEffect(() => {
    if (selectedRows?.length) setShowDeleteIconBar(true);
    else if (allSelectedProductsM?.length) setShowDeleteIconBar(true);
    else setShowDeleteIconBar(false);
  }, [selectedRows, allSelectedProductsM]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (refreshWaitingGrid) {
      setProducts([]);
      fetchProducts();
    }
  }, [refreshWaitingGrid]);

  const handleSeeMore = () => {
    if (totalProducts >= limit) {
      setLimit(limit + defaultLimit);
    }
  };

  const updateBulkProductAsin = async (data: UpdateBulkProductPayload) => {
    const isLimitReached = await isOwnerReachedContentGenLimit(data?.userID);

    if (isLimitReached) {
      if (dbUser?._id === data?.userID) {
        toast.warn(<ToastError error={UnlockGreaterPossibilities} />);
        onOpen();
      } else if (dbUser?._id !== data?.userID) {
        toast.error(UserReachedRegenLimit);
      } else {
      }
    } else {
      generateProductApis
        .updateBulkProduct(data)
        .then(() => {
          toast.success(SUCC_UPDATE_PRODUCT);
          fetchProducts();
          setState({ ...state, loading: false });
        })
        .catch((e: any) => {
          setState({ ...state, loading: false });
          getExceptionError(e);
        });
    }
    setShowUpdateProductBox(false);
    setSelectedRows([]);
  };

  const handleRestrictedAsinRerun = async (productData: any) => {
    const data = {
      id: productData?._id,
      productASIN: productData?.productASIN,
      userID: productData?.userID,
      domain: productData?.domain,
    };
    updateBulkProductAsin(data);
  };

  const handleMultipleDelete = () => setOpenMultipleDeleteConfirm(true);
  const confirmDeleteProduct = async () => {
    if (state.openDeleteConfirm) {
      setState({ ...state, loading: true });
      generateProductApis
        .deleteBulkProducts([state.currentDeleteId])
        .then(() => {
          toast.success(SUCC_DELETE_PRODUCT);
          fetchProducts();
          setState({ ...state, openDeleteConfirm: false, currentDeleteId: "", loading: false });
        })
        .catch((e: any) => {
          setState({ ...state, openDeleteConfirm: false, currentDeleteId: "", loading: false });
          getExceptionError(e);
        });
    } else if (openMultipleDeleteConfirm) {
      let deleteRowsId: string[] = [];
      if (selectedRows?.length) {
        deleteRowsId = selectedRows.map((p) => p._id);
      } else if (allSelectedProductsM?.length) {
        deleteRowsId = allSelectedProductsM;
      } else {
      }
      generateProductApis
        .deleteBulkProducts(deleteRowsId)
        .then(() => {
          toast.success("Your products has been deleted successfully");
          fetchProducts();
          setSelectedRows([]);
          setSelectionModel([]);
          setAllSelectedProductsM([]);
          setOpenMultipleDeleteConfirm(false);
          // setState({ ...state, loading: false });
        })
        .catch((e: any) => {
          setState({ ...state, loading: false });
          errorHandler(e);
          setState({ ...state, loading: false });
          setOpenMultipleDeleteConfirm(false);
          setAllSelectedProductsM([]);
        });
    }
  };

  const handleCellClick = () => {};
  const onSelectedRows = (e: GridSelectionModel) => {
    const selectedIDs = new Set(e);
    setSelectedRows(products.filter((row: any) => selectedIDs.has(row.id)));
    setSelectionModel(e);
  };

  const handleDelete = async (id: string) => setState({ ...state, openDeleteConfirm: true, currentDeleteId: id });

  const handleEditClick = (productData: any) => {
    setShowUpdateProductBox(true);
    setSelectedRows(productData);
  };

  const productsColumns: GridColDef[] = [];
  productsColumns.push({
    field: "productASIN",
    headerName: "ASIN",
    width: 135,
    sortingOrder: ["desc", "asc"],
    renderCell: (params: GridCellParams<Product>) => (
      <div
        style={{
          pointerEvents: "all",
          cursor: loading ? "not-allowed" : "pointer",
        }}
        onContextMenu={(e) => copyAsinByRightClick(e, params.row.productASIN)}
      >
        <div className={"d-flex align-items-center"}>
          <div className={classes.ASIN}>{params.row.productASIN}</div>
        </div>
      </div>
    ),
  });
  productsColumns.push({
    field: "domain",
    headerName: "Marketplace",
    width: 120,
    sortable: false,
    renderCell: (_params: GridCellParams) => (
      <>
        <div className={"w-100"}>
          {/* <Skeleton variant={"text"} /> */}
          {(() => {
            const countryDomainObject = getCountryDomainObjectByName(
              _params.row?.domain || CountryDomainEnum.AMAZON_USA
            ) as CountryDomainObject;

            return (
              <div className={`d-flex align-items-center`}>
                <img src={countryDomainObject.flag} alt={`${countryDomainObject.name} Flag`} className={classes.Flag} />
                {/* <MTypography variant={"subtitle2"} customClass={classes.FlagName}> */}
                <MTypography variant={"subtitle2"} customClass={classes.FlagName}>
                  {countryDomainObject.marketPlaceName}
                </MTypography>
              </div>
            );
          })()}
        </div>
      </>
    ),
  });
  if (showUserEmailInProductsPage(userRole as RolesEnum) || dbUser?.companyID) {
    productsColumns.push({
      field: "userEmail",
      headerName: "User",
      hideable: true,
      width: 300,
      sortable: false,
      renderCell: (_params: any) => (
        <>
          <div className={classes.CellLinesLimitContainer}>
            {_params?.row?.userEmail ? _params?.row?.userEmail : <>---</>}
          </div>
        </>
      ),
    });
  }
  productsColumns.push({
    field: "status",
    headerName: "Status",
    width: 170,
    sortable: false,
    renderCell: (_params: any) => <ProductStatus type={_params.row.status} showInfo />,
  });
  productsColumns.push({
    field: "delete",
    headerName: "",
    sortable: false,
    renderCell: (_params: GridCellParams) =>
      _params.row.status === ContentStatusEnum.WAITING ? (
        <div className={"w-100 d-flex gap-3"}>
          <Skeleton variant={"rectangular"} className={classes.IconSkeleton} />
          <Skeleton variant={"rectangular"} className={classes.IconSkeleton} />
        </div>
      ) : (
        <>
          {(_params.row.status === ContentStatusEnum.NOT_FOUND ||
            _params.row.status === ContentStatusEnum.RESTRICTED) && (
            <div className={"d-flex w-100 gap-1"}>
              {(showDeleteAndEditIconOnWaitingGrid(dbUser?.role) || dbUser?.companyID) && (
                <>
                  {_params.row.status !== ContentStatusEnum.RESTRICTED &&
                    _params.row.status !== ContentStatusEnum.NOT_FOUND && (
                      <div className={""}>
                        <Tooltip title={"Rerun"} placement="top">
                          <RefreshIcon
                            onClick={() => handleRestrictedAsinRerun(_params.row)}
                            color="primary"
                            sx={{
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      </div>
                    )}
                  {(_params.row.status !== ContentStatusEnum.RESTRICTED || !!dbUser?.companyID) && (
                    <div className={""}>
                      <Tooltip title={"Edit"} placement="top">
                        <IconButton onClick={() => handleEditClick(_params.row)}>
                          <ModeEditOutlineOutlinedIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {(showDeleteIconOnWaitingGrid(
                    dbUser?.role,
                    _params.row?.userEmail,
                    dbUser?.email,
                    currentCompany?.role
                  ) ||
                    showDeleteIconOnWaitingGridCompany(currentCompany?.role, dbUser?.role)) && (
                    <div className={""}>
                      <Tooltip title={"Delete"} placement="top">
                        <IconButton onClick={() => handleDelete(_params.row?._id)}>
                          <DeleteIcon style={{ color: "rgb(230, 181, 64)" }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      ),
  });

  const handleClose = () => {
    setShowUpdateProductBox(false);
    setSelectedRows([]);
  };

  return (
    <>
      {products.length > 0 ? (
        <div className={isChildComponent ? "" : `mt-4`}>
          <Accordion
            expanded={expanded}
            onChange={handleExpansion}
            classes={{
              root: expanded ? classes.Root : classes.RootNotActive,
            }}
            sx={{
              "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
              "& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
            }}
          >
            <AccordionSummary
              className={expanded ? classes.AccordianHeader : ""}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <MTypography variant={"h4"} customClass={classes.AccordianHeaderText}>
                {totalProducts} Products in Progress
              </MTypography>
            </AccordionSummary>
            <AccordionDetails sx={isDesktop ? {} : { maxWidth: "100vw", background: "#fffaef" }}>
              {showDeleteIconBar && showMultipleProductDeleteButton(dbUser?.role) && (
                <div style={{ padding: "8px 14px 0px" }}>
                  <MTypography variant={"body1"} customClass={classes.ToolbarOptions}>
                    <div onClick={handleMultipleDelete} className={"d-flex align-items-center cursor-pointer"}>
                      <DeleteIcon className={classes.ToolbarIcon} sx={{ fontSize: "21px" }} />
                      <div style={{ paddingTop: "2px" }}>Delete</div>
                    </div>
                  </MTypography>
                </div>
              )}
              {isDesktop ? (
                <ProductTable
                  total={totalProducts}
                  pageSize={pageSize}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  setPageSize={setPageSize}
                  columns={productsColumns}
                  rows={products ?? []}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        userEmail: dbUser?.companyID ? true : showUserEmailInProductsPage(dbUser?.role),
                      },
                    },
                  }}
                  withoutPagination
                  onCellClick={handleCellClick}
                  setSortModel={setSortModel}
                  onSelectionModelChange={onSelectedRows}
                  selectionModel={selectionModel}
                  checkboxSelection
                  showHeaderCheckBox
                  isRowSelectable={(params) =>
                    params.row.status !== ContentStatusEnum.FETCHING && params.row.status !== ContentStatusEnum.WAITING
                  }
                />
              ) : (
                <>
                  {products.length > 0 && (
                    <>
                      {products?.map((prod: any, index: number) => (
                        <div key={index}>
                          <ProductCardSkeleton
                            productDetail={prod}
                            handleDelete={handleDelete}
                            handleEditClick={handleEditClick}
                            handleMultiCardSelectionM={handleMultiCardSelectionM}
                            handleRestrictedAsinRerun={handleRestrictedAsinRerun}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
              {limit < totalProducts && (
                <div className={"d-flex justify-content-end w-100" + classes.SeeMore}>
                  {loading ? (
                    <CircularProgress size={10} />
                  ) : (
                    <MButton
                      variant={"text"}
                      onClick={handleSeeMore}
                      isLoading={loading}
                      rootClass={classes.SeeMoreButtonText}
                    >
                      See More
                    </MButton>
                  )}
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <div></div>
      )}

      {/* showUpdateProductBox, setShowUpdateProductBox */}
      <UpdateWaitingProductBox
        open={showUpdateProductBox}
        onClose={handleClose}
        updateBulkProductAsin={updateBulkProductAsin}
        selectedRow={selectedRows}
      />
      <ActionBox
        handleAction={confirmDeleteProduct}
        handleBack={closeConfirmDelete}
        open={state.openDeleteConfirm || openMultipleDeleteConfirm}
        actionText={"Delete"}
        message={
          state.openDeleteConfirm ? "Do you want to delete your product?" : "Do you want to delete selected products?"
        }
        title={state.openDeleteConfirm ? "Confirm Delete Product" : "Confirm Delete Selected Products"}
        backText={"Cancel"}
      />
    </>
  );
};

export default memo(WaitingGrid);
