import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";

import { RolesEnum } from "../../../utils/constants/enums";
import { removeUnderscoreAndCapitalize } from "../../../utils/helpers/strings";
import MTypography from "../../Atoms/MTypography";
import classes from "./index.module.css";

interface FromRolesDropdownProps {
  name: string;
  minWidth: string;
  label?: string;
  selectableRoles: string[];
}
const FormRolesDropdown: React.FC<FromRolesDropdownProps> = ({
  name,
  minWidth = "40%",
  label = "",
  selectableRoles = ["Select"],
}): any => {
  const { values, setFieldValue, errors } = useFormikContext<any>();
  const handleChange = (e: any) => {
    setFieldValue(name, e.target.value);
  };

  const [error, setError] = useState<any>("");
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (errors[name]) {
      setError(errors[name]);
    } else {
      setError("");
    }
  }, [errors]);

  // @ts-ignore
  return (
    <FormControl sx={{ minWidth, marginTop: "16px" }}>
      <InputLabel id="select-input-field">{label}</InputLabel>
      <Select
        labelId="roles-label"
        id="roles-select"
        value={values[name]}
        onChange={handleChange}
        name={name}
        label={label}
      >
        {selectableRoles?.map(
          (role: any) =>
            role !== RolesEnum.OWNER && (
              <MenuItem key={role} value={role}>
                {removeUnderscoreAndCapitalize(role)}
              </MenuItem>
            )
        )}
      </Select>
      {error ? (
        <MTypography variant="caption" customClass={classes.ErrorText}>
          {error}
        </MTypography>
      ) : (
        ""
      )}
    </FormControl>
  );
};

export default FormRolesDropdown;
