import * as yup from "yup";

export interface ContactUsObject {
  email: string;
  additionalInformation: string;
}

export const inputName = {
  email: "email",
  additionalInformation: "additionalInformation",
};

export const initialValue: ContactUsObject = {
  email: "",
  additionalInformation: "",
};

export const validationSchema = yup.object().shape({
  additionalInformation: yup.string().trim().required("Additional information is required"),
  email: yup
    .string()
    .trim()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please add valid email address")
    .required("Work email is required"),
});
