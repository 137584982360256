import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import classes from "./index.module.css";
import moment from "moment";
// import { formatNumberWithCommas } from "../../../../../utils/helpers/numbers";

interface BsrPerformanceChartProps {
  chartSeries: any;
  chartCategories: any;
  annotations: any;
  totalTickAmount: any;
  largestNumber: number;
}
const BsrPerformanceOverTime: React.FC<BsrPerformanceChartProps> = ({
  annotations,
  chartSeries,
  chartCategories,
  largestNumber = 0,
}) => {
  const options: ApexOptions = {
    chart: {
      type: "area",
      height: 400,
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    annotations: {
      xaxis: annotations,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
      },
    },
    grid: {
      show: true,
      borderColor: "#D8D8D8",
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 0,
        left: 0,
      },
    },
    fill: {
      type: "solid",
      colors: ["transparent"],
    },
    // markers: {
    //   size: 2,
    //   shape: "circle",
    //   strokeColors: "#ff0000",
    //   colors: "#FFFFFF",
    //   strokeWidth: 8,
    // },
    xaxis: {
      type: "category",
      categories: chartCategories,
      tickAmount: 10,
      labels: {
        hideOverlappingLabels: true,
        showDuplicates: false,
        minHeight: 50,
        style: {
          colors: "#4F4F4F",
          fontSize: "10px",
          fontWeight: 600,
          fontFamily: "Poppins",
        },
        formatter: (value) => {
          return moment(value).format("DD MMM");
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: largestNumber === 1 ? 1 : largestNumber > 9 ? 9 : largestNumber - 1,
      reversed: true,
      min: 0,
      title: {
        text: "High Sales",
        offsetY: -180,
        offsetX: 45,
        rotate: 0,
        style: {
          color: "#0CC683",
          fontSize: "12px",
          fontWeight: 600,
        },
      },
      forceNiceScale: true,
      labels: {
        minWidth: 0,
        align: "right",
        offsetX: -30,
        style: {
          colors: "#4F4F4F",
          fontSize: "10px",
          fontWeight: 600,
          fontFamily: "Poppins",
        },
        formatter(val: number): any {
          // eslint-disable-next-line eqeqeq
          if (val == 0) {
            return "1";
          } else {
            return val;
          }
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        let tooltipContent = `<div class=${classes.TooltipCard}>`;
        tooltipContent += "<div class='d-flex align-content-center'>";
        tooltipContent += `<span class=${classes.TooTipInfoTitle}>BSR :</span><span class=${
          classes.TooTipInfoText
        }># ${data.toFixed()} </span>`;
        tooltipContent += "</div>";
        tooltipContent += "<div class='d-flex mt-1 align-content-center'>";
        tooltipContent += `<span class=${classes.TooTipInfoTitle}>Date :</span><span class=${
          classes.TooTipInfoText
        }>${moment(chartCategories[dataPointIndex]).format("DD MMM, YYYY")}</span>`;
        tooltipContent += "</div>";
        tooltipContent += "</div>";
        return tooltipContent;
      },
    },
    colors: ["#ff0000", "#FE464B"],
  };
  return (
    <div>
      <ReactApexChart options={options} series={chartSeries} type="area" height={400} />
      <p className={classes.Title}>Low Sales</p>
    </div>
  );
};
export default BsrPerformanceOverTime;
