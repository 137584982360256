import React from "react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import MButton from "../../Atoms/MButton";
import AuthorizeAccountDialoge from "../../mui/dialogebox/AuthorizeAccountDialoge";
import { useDeviceType } from "../../../hooks/useDeviceType";

import { getUnique7digitsString } from "./config";
import classes from "./index.module.css";
import { PartnerType } from "../../../utils/constants/enums";

const AuthorizeTopBar = () => {
  const { isMobile, isDesktop } = useDeviceType();
  const [openConfirmBox, setOpenConfirmBox] = useState<boolean>(false);

  const state = getUnique7digitsString();

  const APPLICATION_ID = "amzn1.sp.solution.e29a84c4-04f3-40a9-bcc7-b188c87611f0";
  const SELLER_URL = "https://sellercentral.amazon.com";
  const VENDOR_URL = "https://vendorcentral.amazon.com";

  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState<boolean>(false); //new==false, renew=true

  const startOauth = async (AUTH_URL: string) => {
    if (!mode) {
      window.open(AUTH_URL, "_self");
    } else {
      const amazon_state = searchParams.get("amazon_state");
      const amazon_callback_uri = searchParams.get("amazon_callback_uri");
      const ReAuthURL = `${amazon_callback_uri}?amazon_state=${amazon_state}&version=beta`;
      window.open(ReAuthURL, "_self");
    }
  };

  const handleTypeClick = (type: string) => {
    // setting auth state
    localStorage.removeItem("authState");
    localStorage.setItem("authState", state);
    // setting accountType
    localStorage.removeItem("partner_type");
    localStorage.setItem("partner_type", type);

    if (type === PartnerType.SELLER) {
      const SELLER_OAUTH_URL =
        SELLER_URL + `/apps/authorize/consent?application_id=${APPLICATION_ID}&version=beta&state=${state}&redirect_uri=https://dev.automatoai.com/amazon`;
      startOauth(SELLER_OAUTH_URL);
    } else if (type === PartnerType.VENDOR) {
      const VENDOR_OAUTH_URL =
        VENDOR_URL + `/apps/authorize/consent?application_id=${APPLICATION_ID}&version=beta&state=${state}&redirect_uri=https://dev.automatoai.com/amazon`;
      startOauth(VENDOR_OAUTH_URL);
    } else {
    }
  };

  useEffect(() => {
    for (let val of searchParams.entries()) {
      // console.log(val);
      if (val[0] === "amazon_state") setMode(true);
    }
  }, [searchParams]);

  const closeConfirmBox = () => setOpenConfirmBox(false);

  return (
    <>
      <div className={isDesktop ? classes.Container : classes.ContainerMobile}>
        <div
          className={`${isDesktop ? "d-flex align-items-center gap-2" : "d-flex align-items-center gap-2"} ${
            classes.TextContainer
          }`}
        >
          <InfoOutlinedIcon style={{ color: "#ffffff" }} fontSize={isMobile ? "small" : "medium"} />
          <div className={classes.Text}>
            {isMobile
              ? "Please authorize your Amazon account."
              : "To fully utilize our platform's features, please authorize your Amazon account."}
          </div>
        </div>
        <div
          className={
            isDesktop
              ? `${classes.Button} d-flex justify-content-end align-items-center`
              : `d-flex justify-content-end align-items-center`
          }
        >
          <MButton
            size={"small"}
            variant="contained"
            onClick={() => setOpenConfirmBox(true)}
            rootClass={isMobile ? classes.MButton : ""}
          >
            Authorize
          </MButton>
        </div>
        <AuthorizeAccountDialoge
          handleTypeClick={handleTypeClick}
          title="Select Account Type"
          open={openConfirmBox}
          onClose={closeConfirmBox}
        />
      </div>
    </>
  );
};

export default AuthorizeTopBar;
