import React, { useState } from "react";
import { MuiChipsInput } from "mui-chips-input";
import { Box, IconButton } from "@mui/material";
import classes from "./ChipInput.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { endsWithComma, endsWithSpace } from "../../../utils/helpers/strings";
import { toast } from "react-toastify";
import { INVALID_ASIN_INPUT } from "../../../utils/constants/messages/errors";
import { isValidAmazonASIN } from "../../../utils/helpers/common";

interface ChipInputProps {
  chips: string[];
  handleSetChips: any;
  handleChangeValue: any;
  readonly?: boolean;
  resetForm?: any;
  placeholder?: string;
  isModalView?: boolean;
}

function removeEntriesWithOnlySpaces(arr: any): any {
  let result: any = [];
  arr.forEach((item: string) => {
    if (item !== "") {
      result = [...result, item.trim()];
    }
  });
  return result;
}
const ChipInput: React.FC<ChipInputProps> = ({
  handleChangeValue,
  handleSetChips,
  chips,
  placeholder = "",
  readonly,
  resetForm,
  isModalView
}) => {
  const { isMobile } = useDeviceType();
  const [inputValue, setInputValue] = useState<string>("");
  const setValue = (value: string) => {
    setInputValue(value);
    handleChangeValue(value);
  };

  const handleChangeChips = (newChips: any): boolean | void => {
    const result = removeEntriesWithOnlySpaces(newChips);
    let error = false;
    result.forEach((asin: string) => {
      if (!isValidAmazonASIN(asin)) {
        error = true;
      }
    });
    if (error) {
      toast.error(INVALID_ASIN_INPUT);
      return false;
    }

    handleSetChips(result);
    setValue("");
  };

  const handleInputChange = (value: string) => {
    if (value.length > 1 && (endsWithComma(value) || value.includes(","))) {
      const asins = value.split(",");
      handleChangeChips([...chips, ...asins]);
      setInputValue("");
      setValue("");
    } else if (value.length > 1 && (endsWithSpace(value) || value.includes(" "))) {
      const asins = value.split(" ");
      handleChangeChips([...chips, ...asins]);
      setInputValue("");
      setValue("");
    } else {
      setInputValue(value);
      setValue(value);
    }
  };

  return (
    <Box component={'div'} className={classes.Container} maxWidth={{xs: '100%', sm: '100%'}} >
      <MuiChipsInput
        placeholder={placeholder}
        sx={{
          "& .MuiInputBase-root": {
            // minHeight: !isMobile ? "68px !important" : "40 !important",
            minHeight: isMobile ? "40px !important" : isModalView ? "32px" : "68px !important",
            // maxWidth: {xs:'80%', sm: '100%'},
            color: "#5a5a5a !important",
            display: "flex",
            alignItems: "center",
          },
          "& .MuiInputBase-input": {
            minWidth: { xs: "100% !important", md: "340px !important" }            
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#5a5a5a !important",
          },
        }}
        unselectable={"off"}
        onInputChange={handleInputChange}
        classes={{
          root: classes.Input,
        }}
        disabled={readonly}
        inputValue={inputValue}
        value={chips}
        onChange={handleChangeChips}
        hideClearAll={false}
        aria-readonly={true}
        maxRows={4}
      />
      <div className={isMobile ? classes.IconM : classes.Icon}>
        {readonly && chips.length < 1 && (
          <IconButton
            onClick={() => {
              resetForm();
              setInputValue("");
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </Box>
  );
};

export default ChipInput;
