import { Autocomplete, TextField } from "@mui/material";

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import classes from "./BroadKeywords.module.css";

import { addBlacklistWords, deleteBlackListWord, getListOfBlacklistedWords } from "../../../../apis/keywords";

import { BlacklistWordsEnum } from "../../../../utils/constants/enums";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import ActionBox from "../../../Molecules/ActionBox";
import { useSelector } from "react-redux";
import { UserIdSelector } from "../../../../store/user/user.selector";
import { scrollColor } from "../../../../utils/constants/colors";
import { errorHandler } from "../../../../utils/helpers/apis";

interface Words {
  _id: string;
  word: string;
}

interface WordsT {
  id: string;
  word: string;
}

export interface BlacklistWordsState {
  loading: boolean;
  openModal: boolean;
  typedValue: string;

  blacklistWords: WordsT[];
  // openDeleteConfirm: boolean;
  missingOption: any;
}

const BroadKeywords = () => {
  const { isDesktop } = useDeviceType();
  const [state, setState] = useState<BlacklistWordsState>({
    loading: false,
    openModal: false,
    typedValue: "",
    blacklistWords: [],
    // openDeleteConfirm: false,
    missingOption: {},
  });
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const userID = useSelector(UserIdSelector);

  // let enterPressed = false;
  const options = state.blacklistWords.map((word) => {
    return {
      label: word.word,
      value: word.id,
    };
  });

  const getWordsFromApi = async () => {
    setState({ ...state, loading: true });
    getListOfBlacklistedWords(BlacklistWordsEnum.BROAD)
      .then((resp) => {
        let data = resp?.data?.data as Words[];
        let blacklistWords = data.map((word) => {
          return { id: word._id, word: word.word };
        });
        setState({ ...state, blacklistWords, loading: false });
      })
      .catch(() => {
        setState({ ...state, loading: false });
      });
  };
  const loadKeywords = () => {
    getWordsFromApi().then((res) => console.log(res));
  };

  const closeConfirmDelete = () => {
    setOpenDeleteConfirm(false);
    setState({ ...state, missingOption: {} });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    loadKeywords();
  }, []);

  // @ts-ignore
  const addBlockListElements = async (event: any, value: any) => {
    if (event.key === "Enter") {
      await SubmitBlockListElements();
    } else {
      let deleteOption = options.find(
        (option) => !value.some((item: { label: string; value: string }) => item.value === option.value)
      );
      if (deleteOption) {
        setState({
          ...state,
          missingOption: deleteOption,
        });
        setOpenDeleteConfirm(true);
      } else {
        // handle the case where missingOption is undefined
      }
    }
  };
  const SubmitBlockListElements = async () => {
    if (state.typedValue.trim().length) {
      setState({ ...state, loading: true });

      // // removing , from string
      // reverting below for now
      // const textWithoutCommas = state.typedValue?.replace(/,/g, "");
      // const keywords = textWithoutCommas
      const keywords = state.typedValue
        .split(",")
        .map((keyword) => keyword.trim())
        .filter((keyword) => keyword.trim().length);

      //  // reverting below for now
      // let wordsArray = keywords[0]?.split(" ");

      // await addBlacklistWords(wordsArray)
      await addBlacklistWords({ words: keywords, type: BlacklistWordsEnum.BROAD, userID })
        .then(() => {
          sessionStorage.removeItem("blacklistWords");
          toast.success("Keywords have been added successfully");
          setState({ ...state, loading: false });
          loadKeywords();
        })
        .catch((e: any) => {
          const errorMessage = errorHandler(e);
          setState({ ...state, loading: false });
          toast.error(Array.isArray(errorMessage) ? errorMessage.join("") : "Cannot Add Blacklisted Keywords");
        });
    } else toast.error("Cannot Add Empty Blacklisted Keywords");
  };

  const handleDeleteBlackListWord = async () => {
    setState({ ...state, loading: true });
    await deleteBlackListWord(state.missingOption?.value)
      .then(() => {
        const blacklistWords = state.blacklistWords.filter(
          (word) => state.missingOption && word.word !== state.missingOption?.label
        );
        loadKeywords();
        setState({
          ...state,
          loading: false,
          missingOption: {},
          blacklistWords,
        });
        setOpenDeleteConfirm(false);
        toast.success("Blocklist word has been deleted successfully");
      })
      .catch(() => {
        setState({ ...state, loading: false });
        toast.error("Cannot Delete Blacklist Word");
      });
  };
  return (
    <>
      <div className={isDesktop ? classes.Container : classes.ContainerM} style={{}}>
        <div className={""}>
          <p className={classes.Title}>Broad Block</p>
          <p className={classes.Subtitle}>
            The broad block section will remove any keyword, even if it's within a word or separated by spaces.
          </p>
        </div>
        <div className={`d-flex mt-3 `}>
          <Autocomplete
            multiple
            value={options}
            id="multiple-limit-tags"
            freeSolo
            className={`${classes.AutoComplete}`}
            openText={""}
            defaultValue={[]}
            onChange={(event, selectedOptions) => {
              const selectedIds = selectedOptions.map((option) => {
                return option;
              });
              addBlockListElements(event, selectedIds);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => setState({ ...state, typedValue: e.target.value })}
                label={options.length ? "Words" : "Click to add words"}
                className={classes.Input}
                placeholder="Add Word"
                rows={3}
              />
            )}
            sx={{
              maxWidth: "100%",
              "&  .MuiAutocomplete-inputRoot": {
                maxHeight: "200px",
                overflowY: "auto",
              },
              "& .MuiAutocomplete-inputRoot::-webkit-scrollbar": {
                width: "8px !important",
                height: "8px !important",
                borderRadius: "2px",
              },
              "& .MuiAutocomplete-inputRoot::-webkit-scrollbar-track": {
                background: "#f1f1f1 !important",
              },
              "& .MuiAutocomplete-inputRoot::-webkit-scrollbar-thumb": {
                backgroundColor: `${scrollColor} !important`,
              },
              "& .MuiAutocomplete-inputRoot::-webkit-scrollbar-thumb:hover": {
                background: "#555 !important",
              },
              "& .MuiAutocomplete-endAdornment": {
                display: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                display: "none",
              },
              "& .MuiFormLabel-root": {
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "16px",
                color: "black",
                marginBottom: "10px",
              },
              "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                padding: "24.5px 4px 7.5px 6px",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "16px",
                minWidth: "100px",
              },
              "& .MuiChip-deleteIcon": {
                color: "dimgray",
                fontSize: "14px",
                lineHeight: "16px",
              },
              "& .MuiChip-label": {
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "norma",
                letterSpacing: "-0.08px",
                color: "#71747d",
              },
              "& .MuiAutocomplete-tag": {
                marginTop: "15px",
              },
              width: "100%",
              margin: isDesktop ? "" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            options={[]}
          />
        </div>

        <ActionBox
          handleAction={handleDeleteBlackListWord}
          handleBack={closeConfirmDelete}
          open={openDeleteConfirm}
          actionText={"Delete"}
          message={"Do you want to delete keywords?"}
          title={"Confirm Delete Keywords"}
          backText={"Cancel"}
        />
      </div>
    </>
  );
};

export default BroadKeywords;
