import { Dispatch } from "redux";
import { CompleteProduct, Product } from "../../../apis/types/generate-product";
import { ProductLive } from "../../../apis/generate-product";
import { ContentStatusEnum } from "../../../utils/constants/enums";
import { setChangeKeywordsProductOwnerIdDispatch } from "../../../store/product/product.actions";
import { resetScrapeProduct } from "../../../store/product/productScrape/productScrape.actions";
import { Location, NavigateFunction } from "react-router-dom";
import { PRIORITISE_KEYWORDS, URL_PRODUCT_FETCHED } from "../../../routes/routes-path";
import { ShowChangeKeywordsButton } from "../../../utils/helpers/priviligesChecks";


export const formatNumberWithNearestWholeNumber = (number: any): string => {
  let num = Number(number);
  if (isNaN(num) && number === "∞") {
    return "∞";
  }
  const roundedNumber = Math.round(num);
  return roundedNumber.toLocaleString("en-US");
};

export const calculatePercentageChange = (
  before: number = 0,
  after: number = 0
): { percentageChange: string; isIncrease: boolean } => {
  const change = after - before;

  if (before === 0 && after === 0) {
    return { percentageChange: "0", isIncrease: true };
  } else if (before === after) {
    return { percentageChange: "0", isIncrease: true };
  } else if (before === 0) {
    return { percentageChange: "∞", isIncrease: after > 0 };
  } else {
  }
  const percentageChange = Math.abs((change / before) * 100).toFixed(2);
  const isIncrease = change > 0;
  return { percentageChange: `${Math.round(Number(percentageChange))}`, isIncrease };
};

// to calculate over average competitors
function getCompetitorsCumulativeSearchVolumeTotal(genCompetitors: any) {
  let sumOfCompetitorsSV = 0;
  let noOfAddedCompetitorsSV = 0;

  if (Array.isArray(genCompetitors)) {
    genCompetitors?.forEach((obj) => {
      if (obj?.hasOwnProperty("contentScoreAndSearchVolume")) {
        sumOfCompetitorsSV += obj?.contentScoreAndSearchVolume?.cumulativeSearchVolume;
        noOfAddedCompetitorsSV++;
      }
    });
  }

  return { sumOfCompetitorsSV, noOfAddedCompetitorsSV };
}
export const calculateChangeOverAvgCompetitors = (competitorsList: any, after: any) => {
  const sumOfCompetitorsSearchVolume = getCompetitorsCumulativeSearchVolumeTotal(competitorsList)
  const sumOfAvgComp = sumOfCompetitorsSearchVolume?.sumOfCompetitorsSV;
  const totalNoOfComp = sumOfCompetitorsSearchVolume?.noOfAddedCompetitorsSV;

  if (after === 0) {
    return { changeOverAvgCompetitors: "0", isIncrease: true };
  } else if (after === 0 && sumOfAvgComp === 0) {
    return { changeOverAvgCompetitors: "0", isIncrease: true };
  } else if (sumOfAvgComp === 0 || totalNoOfComp === 0) {
    return { changeOverAvgCompetitors: "100", isIncrease: true };
  } else {
    // calculating avg of competitors
    const averageOfCompetitorsSVE = sumOfAvgComp / totalNoOfComp;
    const afterSubFromAvg = after - averageOfCompetitorsSVE;
    const divByAfter = afterSubFromAvg / after;
    // percentage value
    const finalPercentVal = divByAfter * 100;
    const changeOverAvgCompetitors = Math.abs(finalPercentVal).toFixed(2)
    return { changeOverAvgCompetitors, isIncrease: finalPercentVal >= 0 };
  }
};

export const showIncompleteBullet = (completeProduct: CompleteProduct) => {
  const { generatedTitle, bulletPointsByChat } = completeProduct;
  if (completeProduct?.status === ContentStatusEnum.ERROR) return true;

  if (bulletPointsByChat && bulletPointsByChat.length < 1) {
    return true;
  }

  if (generatedTitle?.length < 20) return true;
  if (bulletPointsByChat?.length)
    for (let bullet of bulletPointsByChat) {
      if (bullet.length < 20) return true;
    }
  return false;
};
export const productInactiveCount = (productLive?: ProductLive) => {
  let count = 0;
  if (productLive) {
    if (!productLive?.isTitleLive) count++;
    if (!productLive?.isBulletPointsLive) count++;
  }
  return count;
};

export const filterKeywordsUsingEdited = ({ keywords, product }: any): any => {
  try {
    const result: any = [];
    const bulletPoints = product?.bulletPointsByChatEdited?.length
      ? product?.bulletPointsByChatEdited.join("")
      : product?.bulletPointsByChat
        ? product?.bulletPointsByChat.join("")
        : "";
    const title =
      product?.editedGeneratedProductContent?.title !== undefined
        ? product?.editedGeneratedProductContent?.title
        : product?.generatedTitle;
    const description =
      product?.editedGeneratedProductContent?.description !== undefined
        ? product?.editedGeneratedProductContent?.description
        : product?.generatedDescription;
    keywords.forEach((item: any) => {
      const keyword = item?.phrase || item;
      if (
        title.toLowerCase().includes(keyword.toLowerCase()) ||
        bulletPoints.toLowerCase().includes(keyword.toLowerCase()) ||
        description.toLowerCase().includes(keyword.toLowerCase())
      ) {
        result.push(item);
      }
    });
    return result;
  } catch (e) {
    return keywords;
  }
};

export const checkValidObj = (obj: ProductLive | null, status: ContentStatusEnum) => {
  if (obj && status) {
    if (Object.keys(obj).length === 0) {
      return false;
    }
    const values = Object.values(obj);
    if (values.some((value) => value === null)) {
      return false;
    }
    return true && (status === ContentStatusEnum.APPROVED || status === ContentStatusEnum.PUBLISHED);
  }
  return false;
};

export const showProductLiveStatus = (obj: ProductLive | null, status: ContentStatusEnum) => {
  if (obj && status) {
    if (Object.keys(obj).length === 0) {
      return false;
    }
    return true && (status === ContentStatusEnum.APPROVED || status === ContentStatusEnum.PUBLISHED);
  }
  return false;
};

export const getChangeValueInX = (changeVal: string) => {
  const roundedVal = Number(changeVal);
  // const roundedVal = Math.round(value)

  if (roundedVal >= 1 && roundedVal < 99.5) {
    return `${roundedVal.toFixed()}%`;
  } else if (roundedVal >= 100 || roundedVal >= 99.5) {
    const valueInX = roundedVal / 100 + 1;
    const result = valueInX.toFixed(1);
    const inNumber = Number(result);
    const decimalPart = inNumber % 1 === 0 ? "" : `.${Math.round((valueInX % 1) * 10)}`;
    return `${Math.floor(inNumber)}${decimalPart}x`;
  } else {
    return "0";
  }
};

export const handleChangeValue = (changeValue: string) => {
  const val = formatNumberWithNearestWholeNumber(changeValue) === "∞" ? "∞" : `${getChangeValueInX(changeValue)}`;
  return val;
};

export const handleChangeKeywordsClick = (completeProduct: CompleteProduct | Product, dispatch: Dispatch, navigate: NavigateFunction, location: Location) => {
  if (completeProduct?.userID) {
    dispatch(setChangeKeywordsProductOwnerIdDispatch(completeProduct?.userID));
    localStorage.setItem("productOwnerId", completeProduct?.userID);
    resetScrapeProduct(dispatch);
    navigate(`${URL_PRODUCT_FETCHED}/${"com"}/${completeProduct.productASIN}${PRIORITISE_KEYWORDS}`, {
      state: { productOwnerId: completeProduct?.userID, originPage: location.state?.isChildComponent },
    });
  }
};

export const showChangeKeywordButton = (completeProduct: CompleteProduct | Product, currentUser: any) => {
  return (completeProduct?.userID === currentUser?._id ||
    ShowChangeKeywordsButton(currentUser?.role, currentUser?.companyID || "")) &&
    (completeProduct?.selectedHelium10Keywords?.length > 0 || completeProduct?.status !== ContentStatusEnum.GENERATING)
}